import {createAction, props} from "@ngrx/store";
import {UserToken} from "../../models/auth.model";

//#region User token actions
export const loadUserToken = createAction('[Access] Load Tokens');

export const loadUserTokenSuccess = createAction(
  '[Access] Load user token Success',
    props<{ token: UserToken }>()
);

export const loadUserTokenFail = createAction(
  '[Access] Load user token Fail',
  props<{ error: any }>()
);

export const setUserToken = createAction(
  '[Access] Set user token',
    props<{ token: UserToken }>()
);


export const removeUserToken = createAction(
  '[Access] Remove user token'
);

export const setUserTokenFail = createAction(
  '[Access] Set user token fail',
  props<{ error: any }>()
);

export const setUserTokenSuccess = createAction(
  '[Access] Set user token success',
    props<{ token: UserToken }>()
);

export const removeUserTokenSuccess = createAction('[Access] Remove user token success');
//#endregion

export const setAccessToken = createAction(
  '[Access] Set access token',
    props<{ accessToken: string }>()
);

export const accessOperationSuccess = createAction(
  '[Access] User token operation success',
  props<{ payload: any, operation: string }>()
);

export const accessOperationFail = createAction(
  '[Access] User token operation fail',
  props<{ error: any, operation: string }>()
);
