import {Injectable, OnDestroy} from "@angular/core";
import {Capacitor, CapacitorHttp, HttpOptions} from "@capacitor/core";
import patientList from "../../assets/viewcontent-patient-list.json";
import patientDetails from "../../assets/dummy-data/viewcontent-overview-781a8414-6aac-41f7-acf0-40809f736404.json";
// import patientDetails from '../../assets/viewcontent-patient-overview.json';
import {PatientListItem,} from "../models/patient.model";
import {DeviceRegisterTokenApiResponse} from "../models/auth.model";
import {Device, DeviceRegisterData, PaginatedData, SuccessResponse, User,} from "../models/cp2-api.model";
import orgaData from "../../assets/viewcontent-orga.json";
import dashboardInfo from "../../assets/dummy-data/viewcontent-dashboard.json";
import {Device as CapacitorDevice} from "@capacitor/device";
import {packageJson} from "../../environments/env";
import {environment} from "../../environments/environment";

import {OrgaViewContent, PatientDetailsViewContent, PatientListItemViewContent,} from "../models/view-content.model";
import {AnonymizationService} from "../helped/anonymization.service";
import {SettingsFacadeService} from "./facades/settings-facade.service";
import {Subscription} from "rxjs";
import {Settings} from "../models/settings.model";
import {Findings} from "../components/patient-info/patient-files/patient-files.component";

@Injectable({
    providedIn: "root",
})
export class Cp2ApiService implements OnDestroy {
    private static readonly TAG = "Cp2ApiService";
    public activeAnonymous!: boolean;
    private allSubs: Subscription[] = [];

    public constructor(
        private anonymizationService: AnonymizationService,
        private settingsFacade: SettingsFacadeService
    ) {
    }

    ngOnDestroy() {
        this.allSubs.forEach(sub => sub.unsubscribe());
    }

    //#region Device
    public async requestDeviceRegisterToken(
        accessToken: string,
        userId: number
    ): Promise<DeviceRegisterTokenApiResponse> {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + "/device/token",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`, // Added accessToken in the Authorization header
            },
            data: {userId},
        });

        return response.data;
    }

    //#endregion

    public async requestDeviceRegister(
        accessToken: string,
        registerToken: string,
        deviceId: string,
        displayName: string
    ): Promise<DeviceRegisterData> {
        const response: SuccessResponse<DeviceRegisterData> =
            await CapacitorHttp.post({
                url: environment.apiServer + "/device/register",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "X-Authorization": `Bearer ${accessToken}`,
                },
                data: {registerToken, deviceId, displayName},
            });

        return response.data;
    }

    public async getAllDevicesPage(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> =
            await CapacitorHttp.get({
                url: environment.apiServer + "/admin/devices",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "X-Authorization": `Bearer ${accessToken}`,
                },
                params: {
                    page: page.toString(),
                    page_size: pageSize.toString(),
                },
            });

        return response.data;
    }

    public async getDeviceId(
        deviceId: number,
        accessToken: string
    ): Promise<Device> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + "/admin/devices/" + deviceId,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async getDevicesForUserIdPage(
        userId: number,
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> =
            await CapacitorHttp.get({
                url: environment.apiServer + "/admin/users/devices/" + userId,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "X-Authorization": `Bearer ${accessToken}`,
                },
                params: {
                    page: page.toString(),
                    page_size: pageSize.toString(),
                },
            });

        return response.data;
    }

    public async removeDeviceFromUser(
        deviceId: number,
        userId: number,
        accessToken: string
    ): Promise<{ message: string }> {
        const response: SuccessResponse<{ message: string }> =
            await CapacitorHttp.delete({
                url:
                    environment.apiServer +
                    `/admin/devices/${deviceId}/user/${userId}/`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    "X-Authorization": `Bearer ${accessToken}`,
                },
            });

        return response.data;
    }

    public async deleteDevice(id: number, accessToken: string): Promise<any> {
        const url = environment.apiServer + `/admin/devices/${id}`;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "X-Authorization": `Bearer ${accessToken}`,
        };

        const response = await CapacitorHttp.delete({url, headers});

        return response.data;
    }

    public async patchDevice(
        device: Device,
        accessToken: string
    ): Promise<any> {
        const response = await CapacitorHttp.patch({
            url: environment.apiServer + `/admin/devices/${device.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`,
            },
            data: {...device},
        });

        return response.data;
    }

    //#region Users
    public async getAllUsersPage(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<User>> {
        const httpOptions: HttpOptions = {
            url: environment.apiServer + "/admin/users",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`,
            },
            params: {
                page: page.toString(),
                page_size: pageSize.toString(),
            },
        };

        const response: SuccessResponse<PaginatedData<User>> =
            await CapacitorHttp.get(httpOptions);

        return response.data ?? [];
    }

    //#endregion

    public async getUsersId(
        userId: number,
        accessToken: string
    ): Promise<User> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + "/admin/users/" + userId,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`,
            },
        });

        return response.data;
    }

    public async patchUser(user: User, accessToken: string): Promise<User> {
        const url = environment.apiServer + "/admin/users/" + user.id;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "X-Authorization": `Bearer ${accessToken}`,
        };
        const data = {...user};

        const response: SuccessResponse<User> = await CapacitorHttp.patch({
            url,
            headers,
            data,
        });

        return response.data;
    }

    public async postUser(user: User, accessToken: string) {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + "/admin/users",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
                "X-Authorization": `Bearer ${accessToken}`,
            },
            data: {...user},
        });

        return response.data;
    }

    getPatients() {
        this.loadInitialSettings();
        if (this.activeAnonymous) {
            const adjustedpatientList = JSON.parse(JSON.stringify(patientList));

            adjustedpatientList.data = adjustedpatientList.data.map(
                (patientListItem: any) => {
                    const anonymizedFirstName =
                        this.anonymizationService.anonymisiereFirstname(
                            patientListItem.firstName,
                            patientListItem.gender
                        );

                    const anonymizedLastName =
                        this.anonymizationService.anonymisiereName(
                            patientListItem.lastName,
                            patientListItem.gender
                        );
                    const anonymizedDob =
                        this.anonymizationService.adjustDobAndAdmissionDate(
                            patientListItem.dob
                        );
                    const anonymizedAdmissionDate =
                        this.anonymizationService.adjustDobAndAdmissionDate(
                            patientListItem.admissionDate
                        );

                    const anonymizedDischargeDate =
                        patientListItem.discharge.map((el: any) => {
                            const anonymizedAdmissionDate =
                                this.anonymizationService.adjustDischargeDate(
                                    el.dismissalExpectedOn
                                );

                            return {
                                ...el,
                                dismissalExpectedOn: anonymizedAdmissionDate,
                            };
                        });

                    // Update patient's first and last name with anonymized versions
                    return {
                        ...patientListItem,
                        firstName: anonymizedFirstName,
                        lastName: anonymizedLastName,
                        dob: anonymizedDob,
                        admissionDate: anonymizedAdmissionDate,
                        discharge: anonymizedDischargeDate,
                    };
                }
            );

            return adjustedpatientList as unknown as PatientListItemViewContent;
        } else {
            return patientList as unknown as PatientListItemViewContent;
        }
    }

    //#endregion

    public getPatientFindings(): Findings[] {
        return [
            {
                id: "dcd14c8b-af27-4a5d-ad8b-ea270869f7fa",
                createdBy: "Johann Schmidt",
                title: "Routinemäßige Blutuntersuchung",
                order: 3,
                area: {
                    id: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    recordId: "c5725899-b20b-4aff-b349-8fe9f510d47d",
                    version: '1.0',
                    name: 'Labor',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "a2ccfc0a-233e-4758-9da8-d1a7bc636a2c",
                    areaId: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    version: '1.0',
                    name: 'Blutuntersuchung',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-06-01T20:00:00.000Z",
                dateTimeOfRecord: "2023-06-02T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                formioRendererI18n: {
                    "de": {
                        "Patientendetails": "Patientendetails",
                        "Vorname": "Vorname",
                        "Vorname eingeben": "Vorname eingeben",
                        "Nachname": "Nachname",
                        "Nachname eingeben": "Nachname eingeben",
                        "Geburtsdatum": "Geburtsdatum",
                        "Geburtsdatum auswählen": "Geburtsdatum auswählen",
                        "Datum der Blutuntersuchung": "Datum der Blutuntersuchung",
                        "Datum der Blutuntersuchung auswählen": "Datum der Blutuntersuchung auswählen",
                        "Art der Blutuntersuchung": "Art der Blutuntersuchung",
                        "Art der Blutuntersuchung auswählen": "Art der Blutuntersuchung auswählen",
                        "Laborwerte": "Laborwerte",
                        "Laborwerte eingeben": "Laborwerte eingeben",
                        "Klinische Bewertung": "Klinische Bewertung",
                        "Klinische Bewertung eingeben": "Klinische Bewertung eingeben",
                        "Absenden": "Absenden"
                    },
                    "en": {
                        "Patientendetails": "Patient Details",
                        "Vorname": "First Name",
                        "Vorname eingeben": "Enter First Name",
                        "Nachname": "Last Name",
                        "Nachname eingeben": "Enter Last Name",
                        "Geburtsdatum": "Date of Birth",
                        "Geburtsdatum auswählen": "Select Date of Birth",
                        "Datum der Blutuntersuchung": "Blood Test Date",
                        "Datum der Blutuntersuchung auswählen": "Select Blood Test Date",
                        "Art der Blutuntersuchung": "Type of Blood Test",
                        "Art der Blutuntersuchung auswählen": "Select Type of Blood Test",
                        "Laborwerte": "Lab Results",
                        "Laborwerte eingeben": "Enter Lab Results",
                        "Klinische Bewertung": "Clinical Evaluation",
                        "Klinische Bewertung eingeben": "Enter Clinical Evaluation",
                        "Absenden": "Submit"
                    }
                },
                formioRendererData: [
                    {key: "firstName", value: "Johann"},
                    {key: "lastName", value: "Schmidt"},
                    {key: "dob", value: "1980-01-01"},
                    {key: "bloodTestDate", value: "2023-06-01"},
                    {key: "bloodTestType", value: "Komplettes Blutbild"},
                    {key: "labResults", value: "Hämoglobin: 13.5, Leukozyten: 6.2"},
                    {key: "clinicalEvaluation", value: "Normale Werte, keine Auffälligkeiten"}
                ],
                formioRendererForm: {
                    components: [
                        {
                            "label": "Patientendetails",
                            "components": [
                                {
                                    "label": "Vorname",
                                    "key": "firstName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Vorname eingeben"
                                },
                                {
                                    "label": "Nachname",
                                    "key": "lastName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Nachname eingeben"
                                },
                                {
                                    "label": "Geburtsdatum",
                                    "key": "dob",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Geburtsdatum auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Datum der Blutuntersuchung",
                                    "key": "bloodTestDate",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Datum der Blutuntersuchung auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Art der Blutuntersuchung",
                                    "key": "bloodTestType",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Art der Blutuntersuchung auswählen"
                                },
                                {
                                    "label": "Laborwerte",
                                    "key": "labResults",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Laborwerte eingeben"
                                },
                                {
                                    "label": "Klinische Bewertung",
                                    "key": "clinicalEvaluation",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Klinische Bewertung eingeben"
                                }
                            ],
                            "type": "fieldset",
                            "input": false,
                            "key": "patientDetails"
                        }
                    ]
                },
                documentType: 'form'
            },
            {
                id: "dcd14c8b-af27-4a5d-ad8b-ea270869f7fb",
                createdBy: "Johann Schmidt",
                title: "Routinemäßige Blutuntersuchung 2",
                order: 2,
                area: {
                    id: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    recordId: "c5725899-b20b-4aff-b349-8fe9f510d47d",
                    version: '1.0',
                    name: 'Labor',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "a2ccfc0a-233e-4758-9da8-d1a7bc636a2c",
                    areaId: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    version: '1.0',
                    name: 'Blutuntersuchung',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-06-01T20:00:00.000Z",
                dateTimeOfRecord: "2023-06-02T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                formioRendererI18n: {
                    "de": {
                        "Patientendetails": "Patientendetails",
                        "Vorname": "Vorname",
                        "Vorname eingeben": "Vorname eingeben",
                        "Nachname": "Nachname",
                        "Nachname eingeben": "Nachname eingeben",
                        "Geburtsdatum": "Geburtsdatum",
                        "Geburtsdatum auswählen": "Geburtsdatum auswählen",
                        "Datum der Blutuntersuchung": "Datum der Blutuntersuchung",
                        "Datum der Blutuntersuchung auswählen": "Datum der Blutuntersuchung auswählen",
                        "Art der Blutuntersuchung": "Art der Blutuntersuchung",
                        "Art der Blutuntersuchung auswählen": "Art der Blutuntersuchung auswählen",
                        "Laborwerte": "Laborwerte",
                        "Laborwerte eingeben": "Laborwerte eingeben",
                        "Klinische Bewertung": "Klinische Bewertung",
                        "Klinische Bewertung eingeben": "Klinische Bewertung eingeben",
                        "Absenden": "Absenden"
                    },
                    "en": {
                        "Patientendetails": "Patient Details",
                        "Vorname": "First Name",
                        "Vorname eingeben": "Enter First Name",
                        "Nachname": "Last Name",
                        "Nachname eingeben": "Enter Last Name",
                        "Geburtsdatum": "Date of Birth",
                        "Geburtsdatum auswählen": "Select Date of Birth",
                        "Datum der Blutuntersuchung": "Blood Test Date",
                        "Datum der Blutuntersuchung auswählen": "Select Blood Test Date",
                        "Art der Blutuntersuchung": "Type of Blood Test",
                        "Art der Blutuntersuchung auswählen": "Select Type of Blood Test",
                        "Laborwerte": "Lab Results",
                        "Laborwerte eingeben": "Enter Lab Results",
                        "Klinische Bewertung": "Clinical Evaluation",
                        "Klinische Bewertung eingeben": "Enter Clinical Evaluation",
                        "Absenden": "Submit"
                    }
                },
                formioRendererData: [
                    {key: "firstName", value: "Johann"},
                    {key: "lastName", value: "Schmidt"},
                    {key: "dob", value: "1980-01-01"},
                    {key: "bloodTestDate", value: "2023-06-01"},
                    {key: "bloodTestType", value: "Komplettes Blutbild"},
                    {key: "labResults", value: "Hämoglobin: 13.5, Leukozyten: 6.2"},
                    {key: "clinicalEvaluation", value: "Normale Werte, keine Auffälligkeiten"}
                ],
                formioRendererForm: {
                    components: [
                        {
                            "label": "Patientendetails",
                            "components": [
                                {
                                    "label": "Vorname",
                                    "key": "firstName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Vorname eingeben"
                                },
                                {
                                    "label": "Nachname",
                                    "key": "lastName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Nachname eingeben"
                                },
                                {
                                    "label": "Geburtsdatum",
                                    "key": "dob",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Geburtsdatum auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Datum der Blutuntersuchung",
                                    "key": "bloodTestDate",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Datum der Blutuntersuchung auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Art der Blutuntersuchung",
                                    "key": "bloodTestType",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Art der Blutuntersuchung auswählen"
                                },
                                {
                                    "label": "Laborwerte",
                                    "key": "labResults",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Laborwerte eingeben"
                                },
                                {
                                    "label": "Klinische Bewertung",
                                    "key": "clinicalEvaluation",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Klinische Bewertung eingeben"
                                }
                            ],
                            "type": "fieldset",
                            "input": false,
                            "key": "patientDetails"
                        }
                    ]
                },
                documentType: 'form'
            },
            {
                id: "dcd14c8b-af27-4a5d-ad8b-ea270869f7fc",
                createdBy: "Johann Schmidt",
                title: "Routinemäßige Blutuntersuchung 3",
                order: 1,
                area: {
                    id: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    recordId: "c5725899-b20b-4aff-b349-8fe9f510d47d",
                    version: '1.0',
                    name: 'Labor',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "a2ccfc0a-233e-4758-9da8-d1a7bc636a2c",
                    areaId: "95c9665d-ad11-4bf6-b25e-4b64c02196e7",
                    version: '1.0',
                    name: 'Blutuntersuchung',
                    validFrom: '2023-01-01T00:00:00Z',
                    validUntil: '2024-01-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-06-01T20:00:00.000Z",
                dateTimeOfRecord: "2023-06-02T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                formioRendererI18n: {
                    "de": {
                        "Patientendetails": "Patientendetails",
                        "Vorname": "Vorname",
                        "Vorname eingeben": "Vorname eingeben",
                        "Nachname": "Nachname",
                        "Nachname eingeben": "Nachname eingeben",
                        "Geburtsdatum": "Geburtsdatum",
                        "Geburtsdatum auswählen": "Geburtsdatum auswählen",
                        "Datum der Blutuntersuchung": "Datum der Blutuntersuchung",
                        "Datum der Blutuntersuchung auswählen": "Datum der Blutuntersuchung auswählen",
                        "Art der Blutuntersuchung": "Art der Blutuntersuchung",
                        "Art der Blutuntersuchung auswählen": "Art der Blutuntersuchung auswählen",
                        "Laborwerte": "Laborwerte",
                        "Laborwerte eingeben": "Laborwerte eingeben",
                        "Klinische Bewertung": "Klinische Bewertung",
                        "Klinische Bewertung eingeben": "Klinische Bewertung eingeben",
                        "Absenden": "Absenden"
                    },
                    "en": {
                        "Patientendetails": "Patient Details",
                        "Vorname": "First Name",
                        "Vorname eingeben": "Enter First Name",
                        "Nachname": "Last Name",
                        "Nachname eingeben": "Enter Last Name",
                        "Geburtsdatum": "Date of Birth",
                        "Geburtsdatum auswählen": "Select Date of Birth",
                        "Datum der Blutuntersuchung": "Blood Test Date",
                        "Datum der Blutuntersuchung auswählen": "Select Blood Test Date",
                        "Art der Blutuntersuchung": "Type of Blood Test",
                        "Art der Blutuntersuchung auswählen": "Select Type of Blood Test",
                        "Laborwerte": "Lab Results",
                        "Laborwerte eingeben": "Enter Lab Results",
                        "Klinische Bewertung": "Clinical Evaluation",
                        "Klinische Bewertung eingeben": "Enter Clinical Evaluation",
                        "Absenden": "Submit"
                    }
                },
                formioRendererData: [
                    {key: "firstName", value: "Johann"},
                    {key: "lastName", value: "Schmidt"},
                    {key: "dob", value: "1980-01-01"},
                    {key: "bloodTestDate", value: "2023-06-01"},
                    {key: "bloodTestType", value: "Komplettes Blutbild"},
                    {key: "labResults", value: "Hämoglobin: 13.5, Leukozyten: 6.2"},
                    {key: "clinicalEvaluation", value: "Normale Werte, keine Auffälligkeiten"}
                ],
                formioRendererForm: {
                    components: [
                        {
                            "label": "Patientendetails",
                            "components": [
                                {
                                    "label": "Vorname",
                                    "key": "firstName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Vorname eingeben"
                                },
                                {
                                    "label": "Nachname",
                                    "key": "lastName",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Nachname eingeben"
                                },
                                {
                                    "label": "Geburtsdatum",
                                    "key": "dob",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Geburtsdatum auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Datum der Blutuntersuchung",
                                    "key": "bloodTestDate",
                                    "type": "datetime",
                                    "input": true,
                                    "placeholder": "Datum der Blutuntersuchung auswählen",
                                    "format": "yyyy-MM-dd"
                                },
                                {
                                    "label": "Art der Blutuntersuchung",
                                    "key": "bloodTestType",
                                    "type": "textfield",
                                    "input": true,
                                    "placeholder": "Art der Blutuntersuchung auswählen"
                                },
                                {
                                    "label": "Laborwerte",
                                    "key": "labResults",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Laborwerte eingeben"
                                },
                                {
                                    "label": "Klinische Bewertung",
                                    "key": "clinicalEvaluation",
                                    "type": "textarea",
                                    "input": true,
                                    "placeholder": "Klinische Bewertung eingeben"
                                }
                            ],
                            "type": "fieldset",
                            "input": false,
                            "key": "patientDetails"
                        }
                    ]
                },
                documentType: 'form'
            },
            {
                id: "4da5a8ed-9365-4d75-9709-9c6d5ced4d90",
                createdBy: "Jane Müller",
                title: "MRT-Scan",
                order: 1,
                area: {
                    id: "83482f1f-0c43-4d22-8e81-3ed6c19650c6",
                    recordId: "c5725899-b20b-4aff-b349-8fe9f510d47d",
                    version: '1.0',
                    name: 'Radiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "8ba4feae-f002-4f31-b06f-72f19dece33d",
                    areaId: "83482f1f-0c43-4d22-8e81-3ed6c19650c6",
                    version: '1.0',
                    name: 'MRT',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-06-15T20:00:00.000Z",
                dateTimeOfRecord: "2023-06-16T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'pdf',
                documentPath: "./../assets/img/demo_images/ambulanterBrief1.pdf",
            },
            {
                id: "ab0146fc-e65c-4380-a76d-3cf855e789b6",
                createdBy: "Alice Müller",
                title: "CT-Scan",
                order: 1,
                area: {
                    id: "83482f1f-0c43-4d22-8e81-3ed6c19650c6",
                    recordId: "c5725899-b20b-4aff-b349-8fe9f510d47d",
                    version: '1.0',
                    name: 'Radiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "ef481ba4-9234-4fa8-b3bc-dc3ec3d7ba6c",
                    areaId: "83482f1f-0c43-4d22-8e81-3ed6c19650c6",
                    version: '1.0',
                    name: 'CT',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-07-10T20:00:00.000Z",
                dateTimeOfRecord: "2023-07-11T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'image',
                documentPath: 'assets/img/demo_images/ct_scan.jpg',
            },
            {
                id: "daf72223-488e-4129-b198-573b7675bb8f",
                createdBy: "Bob Braun",
                title: "Kardiologischer Checkup",
                order: 1,
                area: {
                    id: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Kardiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "7bb37e0f-2ab1-4ddb-9422-e15e813326d3",
                    areaId: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    version: '1.0',
                    name: 'EKG',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-07-20T20:00:00.000Z",
                dateTimeOfRecord: "2023-07-21T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'image',
                documentPath: 'assets/img/demo_images/0002.JPG',
            },
            {
                id: "c9288bc3-1cec-4362-b957-5e2ed8f1e181",
                createdBy: "Emma Davis",
                title: "Vitalparameter-Messung",
                order: 1,
                area: {
                    id: "97bb7da0-4729-40ed-8d6e-96fa330ba4f7",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Allgemeinmedizin',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "ca76399e-42dd-401b-8405-9d9d4b5e20db",
                    areaId: "97bb7da0-4729-40ed-8d6e-96fa330ba4f7",
                    version: '1.0',
                    name: 'Vitalparameter',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-08-05T20:00:00.000Z",
                dateTimeOfRecord: "2023-08-06T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                formioRendererI18n:
                    {
                        "en": {
                            "Betreuende Institution": "Supervising Institution",
                            "Name": "Name",
                            "Adresse": "Address",
                            "Telefonnummer": "Phone Number",
                            "Ansprechperson": "Contact Person",
                            "Geburtstag am": "Date of Birth",
                            "Aktuelle Wohnanschrift": "Current Address",
                            "Versicherungsnummer": "Insurance Number",
                            "Versicherungsträger": "Insurance Provider",
                            "Vertrauensperson": "Trusted Person",
                            "Verständigt?": "Informed?",
                            "Sachwalter": "Legal Guardian",
                            "Hausarzt": "General Practitioner",
                            "Sicherheit": "Safety",
                            "Maßnahmen zur Unterstützung der Individuellen Sicherheit?": "Measures to Support Individual Safety?",
                            "Wenn ja, welche?": "If yes, which?",
                            "Medikamentensicherheit": "Medication Safety",
                            "Kommunikation": "Communication",
                            "Verbale Kommunikation uneingeschränkt möglich?": "Verbal Communication Possible Without Restrictions?",
                            "Wenn nein, welche Einschränkungen?": "If no, what limitations?",
                            "Seit wann bestehend?": "Since when?",
                            "Bewusstseinslage": "Consciousness",
                            "Orientierung": "Orientation",
                            "zur Person": "to Person",
                            "zur Zeit": "to Time",
                            "zum Ort": "to Place",
                            "zur Situation": "to Situation",
                            "Hörgerät?": "Hearing Aid?",
                            "Eigene vorhanden?": "Own Available?",
                            "Mitgegeben wurden..": "Given with..",
                            "Sehbehelf": "Visual Aid",
                            "Eigener vorhanden?": "Own Available?",
                            "Atmen": "Breathing",
                            "Gesundheitsdefizit beim Atmen": "Health Deficit in Breathing",
                            "Seit wann bestehend": "Existing since",
                            "Behelfene Hilfmittel": "Aided Devices",
                            "Ressourcen": "Resources",
                            "Essen und Trinken": "Eating and Drinking",
                            "Gesundheitsdefizit bei Ernährungs- und Trinkgewohnheiten": "Health Deficit in Eating and Drinking Habits",
                            "Diät Kostform": "Diet Form",
                            "Grad der Pflegeabhängigkeit": "Degree of Care Dependency",
                            "Behelfene Hilfsmittel": "Aided Devices",
                            "Eigene Beihilfe/Hilfsmittel vorhanden": "Own Aid/Devices Available",
                            "Schmerz": "Pain",
                            "Gesundheitsdefizit Schmerzen": "Health Deficit Pain",
                            "Bewegung": "Movement",
                            "Gesundheitsdefizit bei der Bewegung": "Health Deficit in Movement",
                            "Zahnprothese?": "Dental Prosthesis?",
                            "Sturzgefahr?": "Fall Risk?",
                            "Prävention": "Prevention",
                            "Gesundheitsdefizit Prävention": "Health Deficit Prevention",
                            "Wunde?": "Wound?",
                            "Art der Wunde": "Type of Wound",
                            "Pflegeanamnese": "Nursing History",
                            "Pflegediagnosen": "Nursing Diagnoses",
                            "Behandlungsziele": "Treatment Goals",
                            "Bisherige Behandlung": "Previous Treatment",
                            "Übergabe": "Handover",
                            "Bisherige Zuständigkeiten": "Previous Responsibilities",
                            "Aktuelle Verantwortlichkeiten": "Current Responsibilities",
                            "Anmerkungen": "Notes"
                        },
                        "de": {
                            "Betreuende Institution": "Betreuende Institution",
                            "Name": "Name",
                            "Adresse": "Adresse",
                            "Telefonnummer": "Telefonnummer",
                            "Ansprechperson": "Ansprechperson",
                            "Geburtstag am": "Geburtstag am",
                            "Aktuelle Wohnanschrift": "Aktuelle Wohnanschrift",
                            "Versicherungsnummer": "Versicherungsnummer",
                            "Versicherungsträger": "Versicherungsträger",
                            "Vertrauensperson": "Vertrauensperson",
                            "Verständigt?": "Verständigt?",
                            "Sachwalter": "Sachwalter",
                            "Hausarzt": "Hausarzt",
                            "Sicherheit": "Sicherheit",
                            "Maßnahmen zur Unterstützung der Individuellen Sicherheit?": "Maßnahmen zur Unterstützung der Individuellen Sicherheit?",
                            "Wenn ja, welche?": "Wenn ja, welche?",
                            "Medikamentensicherheit": "Medikamentensicherheit",
                            "Kommunikation": "Kommunikation",
                            "Verbale Kommunikation uneingeschränkt möglich?": "Verbale Kommunikation uneingeschränkt möglich?",
                            "Wenn nein, welche Einschränkungen?": "Wenn nein, welche Einschränkungen?",
                            "Seit wann bestehend?": "Seit wann bestehend?",
                            "Bewusstseinslage": "Bewusstseinslage",
                            "Orientierung": "Orientierung",
                            "zur Person": "zur Person",
                            "zur Zeit": "zur Zeit",
                            "zum Ort": "zum Ort",
                            "zur Situation": "zur Situation",
                            "Hörgerät?": "Hörgerät?",
                            "Eigene vorhanden?": "Eigene vorhanden?",
                            "Mitgegeben wurden..": "Mitgegeben wurden..",
                            "Sehbehelf": "Sehbehelf",
                            "Eigener vorhanden?": "Eigener vorhanden?",
                            "Atmen": "Atmen",
                            "Gesundheitsdefizit beim Atmen": "Gesundheitsdefizit beim Atmen",
                            "Seit wann bestehend": "Seit wann bestehend",
                            "Behelfene Hilfmittel": "Behelfene Hilfmittel",
                            "Ressourcen": "Ressourcen",
                            "Essen und Trinken": "Essen und Trinken",
                            "Gesundheitsdefizit bei Ernährungs- und Trinkgewohnheiten": "Gesundheitsdefizit bei Ernährungs- und Trinkgewohnheiten",
                            "Diät Kostform": "Diät Kostform",
                            "Grad der Pflegeabhängigkeit": "Grad der Pflegeabhängigkeit",
                            "Behelfene Hilfsmittel": "Behelfene Hilfsmittel",
                            "Eigene Beihilfe/Hilfsmittel vorhanden": "Eigene Beihilfe/Hilfsmittel vorhanden",
                            "Schmerz": "Schmerz",
                            "Gesundheitsdefizit Schmerzen": "Gesundheitsdefizit Schmerzen",
                            "Bewegung": "Bewegung",
                            "Gesundheitsdefizit bei der Bewegung": "Gesundheitsdefizit bei der Bewegung",
                            "Zahnprothese?": "Zahnprothese?",
                            "Sturzgefahr?": "Sturzgefahr?",
                            "Prävention": "Prävention",
                            "Gesundheitsdefizit Prävention": "Gesundheitsdefizit Prävention",
                            "Wunde?": "Wunde?",
                            "Art der Wunde": "Art der Wunde",
                            "Pflegeanamnese": "Pflegeanamnese",
                            "Pflegediagnosen": "Pflegediagnosen",
                            "Behandlungsziele": "Behandlungsziele",
                            "Bisherige Behandlung": "Bisherige Behandlung",
                            "Übergabe": "Übergabe",
                            "Bisherige Zuständigkeiten": "Bisherige Zuständigkeiten",
                            "Aktuelle Verantwortlichkeiten": "Aktuelle Verantwortlichkeiten",
                            "Anmerkungen": "Anmerkungen"
                        }
                    },
                formioRendererData: [
                    {key: "betreuende_institution", value: "Krankenhaus Musterstadt"},
                    {key: "fullname", value: "Max Mustermann"},
                    {key: "address", value: "Musterstraße 1, 12345 Musterstadt"},
                    {key: "telefonnummer", value: "01234 567890"},
                    {key: "ansprechperson", value: "Dr. Erika Mustermann"},
                    {key: "name_1", value: "Max Mustermann"},
                    {key: "geburtstag_am", value: "1980-01-01"},
                    {key: "adresse_1", value: "Musterstraße 1, 12345 Musterstadt"},
                    {key: "aktuelle_wohnanschrift", value: "Musterstraße 1, 12345 Musterstadt"},
                    {key: "versicherungsnummer", value: "1234567890"},
                    {key: "versicherungstraeger", value: "Versicherung Muster"},
                    {key: "vertrauensperson", value: "Erika Mustermann"},
                    {key: "verstaendigt_1", value: "a"},
                    {key: "name_2", value: "John Doe"},
                    {key: "adresse_2", value: "Beispielstraße 2, 54321 Beispielstadt"},
                    {key: "sachwalter", value: "Dr. Erika Mustermann"},
                    {key: "verstaendigt_2", value: "b"},
                    {key: "name_3", value: "Jane Smith"},
                    {key: "adresse_3", value: "Testweg 3, 98765 Teststadt"},
                    {key: "hausarzt", value: "Dr. Erika Mustermann"},
                    {key: "name_4", value: "Dr. Erika Mustermann"},
                    {key: "adresse_4", value: "Musterstraße 1, 12345 Musterstadt"},
                    {key: "telefon", value: "01234 567890"},
                    {key: "sicherheit", value: "Maßnahmen zur Unterstützung der Sicherheit"},
                    {key: "massnahmen_sicherheit", value: "a"},
                    {key: "massnahmen_sicherheit_text", value: "Sicherheitsgurt"},
                    {key: "medikamentensicherheit", value: "a"},
                    {key: "kommunikation", value: "Verbale Kommunikation"},
                    {key: "verbale_kommunikation", value: "a"},
                    {key: "kommunikation_einschraenkungen", value: "Keine"},
                    {key: "kommunikation_seit_wann", value: "N/A"},
                    {key: "bewusstseinslage", value: "a"},
                    {key: "orientierung", value: "vollständig"},
                    {key: "orientierung_person", value: "a"},
                    {key: "orientierung_zeit", value: "a"},
                    {key: "orientierung_ort", value: "a"},
                    {key: "orientierung_situation", value: "a"},
                    {key: "hoergeraet", value: "b"},
                    {key: "eigene_vorhanden", value: "N/A"},
                    {key: "hoergeraet_mitgegeben", value: "N/A"},
                    {key: "sehbehelf", value: "Ja"},
                    {key: "sehbehelf_eigene", value: "a"},
                    {key: "sehbehelf_mitgegeben", value: "Brille"},
                    {key: "atmen", value: "Gesundheitsdefizit beim Atmen"},
                    {key: "gesundheitsdefizit_atmen", value: "a"},
                    {key: "atmen_welches", value: "Asthma"},
                    {key: "atmen_seit_wann", value: "Seit 10 Jahren"},
                    {key: "atmen_hilfmittel", value: "a"},
                    {key: "atmen_hilfmittel_welche", value: "Inhalator"},
                    {key: "ressourcen", value: "a"},
                    {key: "ressourcen_welche", value: "Sport"},
                    {key: "essen_trinken", value: "Ernährungsgewohnheiten"},
                    {key: "gesundheitsdefizit_ernaehrung_trinken", value: "b"},
                    {key: "essen_trinken_welche", value: "N/A"},
                    {key: "essen_trinken_seit_wann", value: "N/A"},
                    {key: "diaet_kostform", value: "Vegetarisch"},
                    {key: "grad_pflegeabhaengigkeit", value: "c"},
                    {key: "pflegeabhaengigkeit_hilfsmittel", value: "a"},
                    {key: "hilfsmittel_welche", value: "Rollstuhl"},
                    {key: "eigene_hilfsmittel_vorhanden", value: "a"},
                    {key: "eigene_hilfsmittel_welche", value: "Gehstock"},
                    {key: "schmerz", value: "Schmerzen"},
                    {key: "gesundheitsdefizit_schmerz", value: "a"},
                    {key: "gesundheitsdefizit_schmerz_welche", value: "Rückenschmerzen"},
                    {key: "schmerz_seit_wann", value: "Seit 5 Jahren"},
                    {key: "bewegung", value: "Bewegungsfähigkeit"},
                    {key: "gesundheitsdefizit_bewegung", value: "a"},
                    {key: "bewegung_welche", value: "Arthritis"},
                    {key: "bewegung_seit_wann", value: "Seit 3 Jahren"},
                    {key: "zahnprothese", value: "b"},
                    {key: "zahnprothese_anzahl", value: "N/A"},
                    {key: "sturzgefahr", value: "a"},
                    {key: "sturzgefahr_vorbeugen", value: "Handläufe"},
                    {key: "praevention", value: "Prävention"},
                    {key: "gesundheitsdefizit_praevention", value: "b"},
                    {key: "praevention_welche", value: "N/A"},
                    {key: "praevention_seit_wann", value: "N/A"},
                    {key: "wunde", value: "b"},
                    {key: "wunde_art", value: "N/A"},
                    {key: "pflegeanamnese", value: "Pflegeanamnese"},
                    {key: "pflegediagnosen", value: "Keine"},
                    {key: "behandlungsziele", value: "Verbesserung der Mobilität"},
                    {key: "bisherige_behandlung", value: "Physiotherapie"},
                    {key: "uebergabe", value: "Übergabe"},
                    {key: "bisherige_zustaendigkeiten", value: "Hausarzt"},
                    {key: "aktuelle_verantwortlichkeiten", value: "Krankenhaus"},
                    {key: "anmerkungen", value: "Keine weiteren Anmerkungen"}
                ],
                formioRendererForm: {
                    components: [
                        {
                            "label": "Betreuende Institution",
                            "key": "betreuende_institution",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Name",
                            "key": "fullname",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Adresse",
                            "key": "address",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Telefonnummer",
                            "key": "telefonnummer",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Ansprechperson",
                            "key": "ansprechperson",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Name",
                            "key": "name_1",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Geburtstag am",
                            "key": "geburtstag_am",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Adresse",
                            "key": "adresse_1",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Aktuelle Wohnanschrift",
                            "key": "aktuelle_wohnanschrift",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Versicherungsnummer",
                            "key": "versicherungsnummer",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Versicherungsträger",
                            "key": "versicherungstraeger",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Vertrauensperson",
                            "key": "vertrauensperson",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Verständigt?",
                            "key": "verstaendigt_1",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Name",
                            "key": "name_2",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Adresse",
                            "key": "adresse_2",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Sachwalter",
                            "key": "sachwalter",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Verständigt?",
                            "key": "verstaendigt_2",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Name",
                            "key": "name_3",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Adresse",
                            "key": "adresse_3",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Hausarzt",
                            "key": "hausarzt",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Name",
                            "key": "name_4",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Adresse",
                            "key": "adresse_4",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Telefon",
                            "key": "telefon",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Sicherheit",
                            "key": "sicherheit",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Maßnahmen zur Unterstützung der Individuellen Sicherheit?",
                            "key": "massnahmen_sicherheit",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "massnahmen_sicherheit_text",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Medikamentensicherheit",
                            "key": "medikamentensicherheit",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Medikamenten Einnahmekontrolle",
                                    "value": "a"
                                },
                                {
                                    "label": "Spezielle Verabreichungsform",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Kommunikation",
                            "key": "kommunikation",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Verbale Kommunikation uneingeschränkt möglich?",
                            "key": "verbale_kommunikation",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn nein, welche Einschränkungen?",
                            "key": "kommunikation_einschraenkungen",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend?",
                            "key": "kommunikation_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Bewusstseinslage",
                            "key": "bewusstseinslage",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Wachheit",
                                    "value": "a"
                                },
                                {
                                    "label": "Benommenheit",
                                    "value": "b"
                                },
                                {
                                    "label": "Koma",
                                    "value": "c"
                                },
                                {
                                    "label": "Somnolenz",
                                    "value": "d"
                                },
                                {
                                    "label": "Sopor",
                                    "value": "e"
                                }
                            ]
                        },
                        {
                            "label": "Orientierung",
                            "key": "orientierung",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "zur Person",
                            "key": "orientierung_person",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "völlig",
                                    "value": "a"
                                },
                                {
                                    "label": "teilweise",
                                    "value": "b"
                                },
                                {
                                    "label": "nicht",
                                    "value": "c"
                                }
                            ]
                        },
                        {
                            "label": "zur Zeit",
                            "key": "orientierung_zeit",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "völlig",
                                    "value": "a"
                                },
                                {
                                    "label": "teilweise",
                                    "value": "b"
                                },
                                {
                                    "label": "nicht",
                                    "value": "c"
                                }
                            ]
                        },
                        {
                            "label": "zum Ort",
                            "key": "orientierung_ort",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "völlig",
                                    "value": "a"
                                },
                                {
                                    "label": "teilweise",
                                    "value": "b"
                                },
                                {
                                    "label": "nicht",
                                    "value": "c"
                                }
                            ]
                        },
                        {
                            "label": "zur Situation",
                            "key": "orientierung_situation",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "völlig",
                                    "value": "a"
                                },
                                {
                                    "label": "teilweise",
                                    "value": "b"
                                },
                                {
                                    "label": "nicht",
                                    "value": "c"
                                }
                            ]
                        },
                        {
                            "label": "Hörgerät?",
                            "key": "hoergeraet",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Eigene vorhanden?",
                            "key": "eigene_vorhanden",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Links",
                                    "value": "a"
                                },
                                {
                                    "label": "Rechts",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Mitgegeben wurden..",
                            "key": "hoergeraet_mitgegeben",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Sehbehelf",
                            "key": "sehbehelf",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Eigener vorhanden?",
                            "key": "sehbehelf_eigene",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Mitgegeben wurden..",
                            "key": "sehbehelf_mitgegeben",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Atmen",
                            "key": "atmen",
                            "type": "label",
                            "input": false
                        },
                        {
                            "label": "Gesundheitsdefizit beim Atmen",
                            "key": "gesundheitsdefizit_atmen",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welches?",
                            "key": "atmen_welches",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend",
                            "key": "atmen_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Behelfene Hilfmittel",
                            "key": "atmen_hilfmittel",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "atmen_hilfmittel_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Ressourcen",
                            "key": "ressourcen",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "ressourcen_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Essen und Trinken",
                            "key": "essen_trinken",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Gesundheitsdefizit bei Ernährungs- und Trinkgewohnheiten",
                            "key": "gesundheitsdefizit_ernaehrung_trinken",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "essen_trinken_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend?",
                            "key": "essen_trinken_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Diät Kostform",
                            "key": "diaet_kostform",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Grad der Pflegeabhängigkeit",
                            "key": "grad_pflegeabhaengigkeit",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "0",
                                    "value": "a"
                                },
                                {
                                    "label": "I",
                                    "value": "b"
                                },
                                {
                                    "label": "II",
                                    "value": "c"
                                },
                                {
                                    "label": "III",
                                    "value": "d"
                                },
                                {
                                    "label": "IV",
                                    "value": "e"
                                }
                            ]
                        },
                        {
                            "label": "Behelfene Hilfsmittel",
                            "key": "pflegeabhaengigkeit_hilfsmittel",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn Ja, welche?",
                            "key": "hilfsmittel_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Eigene Beihilfe/Hilfsmittel vorhanden",
                            "key": "eigene_hilfsmittel_vorhanden",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn Ja, welche?",
                            "key": "eigene_hilfsmittel_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Schmerz",
                            "key": "schmerz",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Gesundheitsdefizit Schmerzen",
                            "key": "gesundheitsdefizit_schmerz",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "gesundheitsdefizit_schmerz_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend?",
                            "key": "schmerz_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Bewegung",
                            "key": "bewegung",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Gesundheitsdefizit bei der Bewegung",
                            "key": "gesundheitsdefizit_bewegung",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "bewegung_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend?",
                            "key": "bewegung_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Zahnprothese?",
                            "key": "zahnprothese",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Anzahl",
                            "key": "zahnprothese_anzahl",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Sturzgefahr?",
                            "key": "sturzgefahr",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn Ja, wie kann dieser vorgebeugt werden?",
                            "key": "sturzgefahr_vorbeugen",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Prävention",
                            "key": "praevention",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Gesundheitsdefizit Prävention",
                            "key": "gesundheitsdefizit_praevention",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Wenn ja, welche?",
                            "key": "praevention_welche",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Seit wann bestehend?",
                            "key": "praevention_seit_wann",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Wunde?",
                            "key": "wunde",
                            "type": "radio",
                            "input": true,
                            "values": [
                                {
                                    "label": "Ja",
                                    "value": "a"
                                },
                                {
                                    "label": "Nein",
                                    "value": "b"
                                }
                            ]
                        },
                        {
                            "label": "Art der Wunde",
                            "key": "wunde_art",
                            "type": "textfield",
                            "input": true
                        },
                        {
                            "label": "Pflegeanamnese",
                            "key": "pflegeanamnese",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Pflegediagnosen",
                            "key": "pflegediagnosen",
                            "type": "textarea",
                            "input": true
                        },
                        {
                            "label": "Behandlungsziele",
                            "key": "behandlungsziele",
                            "type": "textarea",
                            "input": true
                        },
                        {
                            "label": "Bisherige Behandlung",
                            "key": "bisherige_behandlung",
                            "type": "textarea",
                            "input": true
                        },
                        {
                            "label": "Übergabe",
                            "key": "uebergabe",
                            "type": "label",
                            "input": true
                        },
                        {
                            "label": "Bisherige Zuständigkeiten",
                            "key": "bisherige_zustaendigkeiten",
                            "type": "textarea",
                            "input": true
                        },
                        {
                            "label": "Aktuelle Verantwortlichkeiten",
                            "key": "aktuelle_verantwortlichkeiten",
                            "type": "textarea",
                            "input": true
                        },
                        {
                            "label": "Anmerkungen",
                            "key": "anmerkungen",
                            "type": "textarea",
                            "input": true
                        }
                    ]
                },
                documentType: 'form'
            },
            {
                id: "8cd53e9f-7ce0-4e09-912f-c195b251bcb8",
                createdBy: "Max Mustermann",
                title: "Jährliche körperliche Untersuchung",
                order: 1,
                area: {
                    id: "97bb7da0-4729-40ed-8d6e-96fa330ba4f7",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Allgemeinmedizin',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "fff73543-04d5-4921-aab6-5feae2a596ea",
                    areaId: "97bb7da0-4729-40ed-8d6e-96fa330ba4f7",
                    version: '1.0',
                    name: 'Anamnese',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1
                },
                examinationDateTime: "2023-08-15T20:00:00.000Z",
                dateTimeOfRecord: "2023-08-16T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'dicom',
                documentPath: 'pfad/zu/physical-exam.dcm',
            },
            {
                id: "daf72223-488e-4129-b198-573b7675bb7f",
                createdBy: "Stefan Schmidt",
                title: "Kardiologischer Schock (Ohne Bereich)",
                order: 1,
                area: {
                    id: "4201d743-e2a8-4aa5-8bb2-88a37c9da668",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Kardiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "c929e7a2-dea3-49d3-b5ff-94172171af4a",
                    areaId: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    version: "1.0",
                    name: "Sauerstoffgabe",
                    validFrom: "2024-07-09T11:38:14.989Z",
                    validUntil: "2024-07-09T11:38:14.989Z",
                    order: 1
                },
                examinationDateTime: "2023-07-20T20:00:00.000Z",
                dateTimeOfRecord: "2023-07-21T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'image',
                documentPath: 'assets/img/demo_images/0002.JPG',
            },
            {
                id: "daf72223-488e-4129-b198-573b7675bb7h",
                createdBy: "Stefan Schmidt",
                title: "Kardiologischer Schock (Ohne Unterbereich)",
                order: 1,
                area: {
                    id: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Kardiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "c929e7a2-dea3-49d3-b5ff-94172171af4b",
                    areaId: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    version: "1.0",
                    name: "Sauerstoffgabe",
                    validFrom: "2024-07-09T11:38:14.989Z",
                    validUntil: "2024-07-09T11:38:14.989Z",
                    order: 1
                },
                examinationDateTime: "2023-07-20T20:00:00.000Z",
                dateTimeOfRecord: "2023-07-21T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'image',
                documentPath: 'assets/img/demo_images/0002.JPG',
            },
            {
                id: "daf72223-488e-4129-b198-573b7675bb7g",
                createdBy: "Stefan Schmidt",
                title: "Kardiologischer Schock (Ohne Bereich sowie Unterbereich)",
                order: 1,
                area: {
                    id: "4201d743-e2a8-4aa5-8bb2-88a37c9da668",
                    recordId: "af27a2a9-81f4-44a7-b72f-58528949fc8d",
                    version: '1.0',
                    name: 'Kardiologie',
                    validFrom: '2023-02-01T00:00:00Z',
                    validUntil: '2024-02-01T00:00:00Z',
                    order: 1,
                    subAreas: []
                },
                subArea: {
                    id: "c929e7a2-dea3-49d3-b5ff-94172171af4b",
                    areaId: "4201d743-e2a8-4aa5-8bb2-88a37c9da669",
                    version: "1.0",
                    name: "Sauerstoffgabe",
                    validFrom: "2024-07-09T11:38:14.989Z",
                    validUntil: "2024-07-09T11:38:14.989Z",
                    order: 1
                },
                examinationDateTime: "2023-07-20T20:00:00.000Z",
                dateTimeOfRecord: "2023-07-21T20:00:00.000Z",
                validFrom: "2023-06-02T20:00:00.000Z",
                validUntil: "2023-06-02T20:00:00.000Z",
                documentType: 'image',
                documentPath: 'assets/img/demo_images/0002.JPG',
            },
        ]
    }

    //#region Patient

    public getWorkflowForOnePatient(
        patientId: string | null
    ): PatientListItem[] | undefined {
        this.loadInitialSettings();
        if (this.activeAnonymous) {
            const updatedOrgaData = JSON.parse(JSON.stringify(orgaData));

            updatedOrgaData.data.orgaData = updatedOrgaData.data.orgaData.map(
                (stations: any) => {
                    return {
                        ...stations,
                        patients: stations.patients.map(
                            (patientListItem: any) => {
                                const anonymizedFirstName =
                                    this.anonymizationService.anonymisiereFirstname(
                                        patientListItem.firstName,
                                        patientListItem.gender
                                    );
                                const anonymizedLastName =
                                    this.anonymizationService.anonymisiereName(
                                        patientListItem.lastName,
                                        patientListItem.gender
                                    );
                                const anonymizedDob =
                                    this.anonymizationService.adjustDobAndAdmissionDate(
                                        patientListItem.dob
                                    );
                                const anonymizedAdmissionDate =
                                    this.anonymizationService.adjustDobAndAdmissionDate(
                                        patientListItem.admissionDate
                                    );

                                const anonymizedDischargeDate =
                                    patientListItem.discharge.map((el: any) => {
                                        const anonymizedAdmissionDate =
                                            this.anonymizationService.adjustDischargeDate(
                                                el.dismissalExpectedOn
                                            );
                                        return {
                                            ...el,
                                            dismissalExpectedOn:
                                            anonymizedAdmissionDate,
                                        };
                                    });
                                return {
                                    ...patientListItem,
                                    firstName: anonymizedFirstName,
                                    lastName: anonymizedLastName,
                                    dob: anonymizedDob,
                                    admissionDate: anonymizedAdmissionDate,
                                    discharge: anonymizedDischargeDate,
                                };
                            }
                        ),
                    };
                }
            );

            for (const station of updatedOrgaData.data.orgaData) {
                // FIXME
                // @ts-ignore
                const patient = station.patients.find(
                    (p: any) => p.id === patientId
                );
                if (patient) {
                    return [patient] as unknown as PatientListItem[];
                }
            }
            return undefined;
        } else {
            for (const station of orgaData.data.orgaData) {
                // FIXME
                // @ts-ignore
                const patient = station.patients.find(
                    (p: any) => p.id === patientId
                );
                if (patient) {
                    return [patient] as unknown as PatientListItem[];
                }
            }
            return undefined;
        }
    }

    //get data for workflow
    // getWorkflowPatients() {
    //     return workflowPatientData.data
    //         .workflowData as unknown as OrgaPatientData[];
    // }

    //get workflow data for one patient

    getPatientById(id: string): PatientListItem {
        this.loadInitialSettings();
        if (this.activeAnonymous) {
            const adjustedpatientList = {...patientList};
            adjustedpatientList.data = adjustedpatientList.data.map(
                (patientListItem) => {
                    const anonymizedFirstName =
                        this.anonymizationService.anonymisiereFirstname(
                            patientListItem.firstName,
                            patientListItem.gender
                        );

                    const anonymizedLastName =
                        this.anonymizationService.anonymisiereName(
                            patientListItem.lastName,
                            patientListItem.gender
                        );
                    const anonymizedDob =
                        this.anonymizationService.adjustDobAndAdmissionDate(
                            patientListItem.dob
                        );
                    const anonymizedAdmissionDate =
                        this.anonymizationService.adjustDobAndAdmissionDate(
                            patientListItem.admissionDate
                        );

                    const anonymizedDischargeDate =
                        patientListItem.discharge.map((el) => {
                            const anonymizedAdmissionDate =
                                this.anonymizationService.adjustDischargeDate(
                                    el.dismissalExpectedOn
                                );
                            return {
                                ...el,
                                dismissalExpectedOn: anonymizedAdmissionDate,
                            };
                        });
                    // Update patient's first and last name with anonymized versions
                    return {
                        ...patientListItem,
                        firstName: anonymizedFirstName,
                        lastName: anonymizedLastName,
                        dob: anonymizedDob,
                        admissionDate: anonymizedAdmissionDate,
                        discharge: anonymizedDischargeDate,
                    };
                }
            );

            return adjustedpatientList.data.find(
                (patient) => patient.id === id
            ) as unknown as PatientListItem;
        } else {
            const filteredPatient = patientList.data.find(
                (patient) => patient.id === id
            );
            return filteredPatient as unknown as PatientListItem;
        }
    }

    public async getPatientDetails(
        id: string
    ): Promise<PatientDetailsViewContent> {
        if (!id) return patientDetails as unknown as PatientDetailsViewContent;

        try {
            const response = await fetch(
                `assets/dummy-data/viewcontent-overview-${id}.json`
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const jsonResponse = await response.json();
            return jsonResponse as PatientDetailsViewContent;
        } catch (error) {
            console.error("Fetching patient details failed:", error);
            throw error;
        }
    }

    getOrgaPatients(): OrgaViewContent {
        this.loadInitialSettings();
        if (this.activeAnonymous) {
            const updatedOrgaData = JSON.parse(JSON.stringify(orgaData));

            updatedOrgaData.data.orgaData = updatedOrgaData.data.orgaData.map(
                (stations: any) => {
                    return {
                        ...stations,
                        patients: stations.patients.map(
                            (patientListItem: any) => {
                                const anonymizedFirstName =
                                    this.anonymizationService.anonymisiereFirstname(
                                        patientListItem.firstName,
                                        patientListItem.gender
                                    );
                                const anonymizedLastName =
                                    this.anonymizationService.anonymisiereName(
                                        patientListItem.lastName,
                                        patientListItem.gender
                                    );
                                const anonymizedDob =
                                    this.anonymizationService.adjustDobAndAdmissionDate(
                                        patientListItem.dob
                                    );
                                const anonymizedAdmissionDate =
                                    this.anonymizationService.adjustDobAndAdmissionDate(
                                        patientListItem.admissionDate
                                    );

                                const anonymizedDischargeDate =
                                    patientListItem.discharge.map((el: any) => {
                                        const anonymizedAdmissionDate =
                                            this.anonymizationService.adjustDischargeDate(
                                                el.dismissalExpectedOn
                                            );
                                        return {
                                            ...el,
                                            dismissalExpectedOn:
                                            anonymizedAdmissionDate,
                                        };
                                    });

                                return {
                                    ...patientListItem,
                                    firstName: anonymizedFirstName,
                                    lastName: anonymizedLastName,
                                    dob: anonymizedDob,
                                    admissionDate: anonymizedAdmissionDate,
                                    discharge: anonymizedDischargeDate,
                                };
                            }
                        ),
                    };
                }
            );

            return updatedOrgaData as unknown as OrgaViewContent;
        } else {
            return orgaData as unknown as OrgaViewContent;
        }
    }

    // #region Dashboard
    async getDashboardInfo(): Promise<any> {
        const deviceInformations = await this.getDeviceInfo();
        const data = {
            ...dashboardInfo,
            buildInformations: this.getBuildInformations(),
            deviceInformations,
        };
        return data;
    }

    //#endregion

    // #region Files

    // #endregion

    //#region load settings
    private loadInitialSettings(): void {
        const sub: Subscription = this.settingsFacade.settings$.subscribe(
            (settings: Settings) => {
                this.activeAnonymous = settings.activeAnonymous;
            }
        );
        this.allSubs.push(sub);
    }

    private getBuildInformations(): any {
        const germanDateString = new Date().toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        return {
            title: "Build Infomation",
            data: [
                {key: "Version", value: packageJson?.version},
                {key: "Datum des Build's", value: germanDateString},
                {key: "Ersteller", value: packageJson.author?.name},
                {key: "E-Mail", value: packageJson.author?.email},
            ],
        };
    }

    private async getDeviceInfo() {
        const deviceInfo: any = await CapacitorDevice.getInfo();
        let systemInfo: any;
        try {
            if (Capacitor.getPlatform() === "ios") {
                systemInfo = {
                    title: "Device Info",
                    data: [
                        {key: "Gerätetyp", value: deviceInfo?.model},
                        {key: "Platform", value: deviceInfo?.platform},
                        {
                            key: "Betriebssystem",
                            value: deviceInfo?.operatingSystem,
                        },
                        {key: "Hersteller", value: deviceInfo?.manufacturer},
                        {key: "iOS Version", value: deviceInfo?.iOSVersion},
                        {
                            key: "Freier Arbeitsspeicher",
                            value: deviceInfo?.memUsed,
                        },
                        {
                            key: "Freier Festplattenspeicher",
                            value: deviceInfo?.realDiskFree,
                        },
                    ],
                };
            } else if (Capacitor.getPlatform() === "android") {
                systemInfo = {
                    title: "Device Info",

                    data: [
                        {key: "Gerätetyp", value: deviceInfo?.model},
                        {key: "Platform", value: deviceInfo?.platform},
                        {
                            key: "Betriebssystem",
                            value: deviceInfo?.operatingSystem,
                        },
                        {key: "Hersteller", value: deviceInfo?.manufacturer},
                        {
                            key: "Android Version",
                            value: deviceInfo?.androidSDKVersion,
                        },
                        {
                            key: "Freier Arbeitsspeicher",
                            value: deviceInfo?.memUsed,
                        },
                        {
                            key: "Freier Festplattenspeicher",
                            value: deviceInfo?.realDiskFree,
                        },
                    ],
                };
            } else if (Capacitor.getPlatform() === "electron") {
                systemInfo = {
                    title: "Device Info",

                    data: [
                        {key: "Gerätetyp", value: deviceInfo?.model},
                        {key: "Platform", value: deviceInfo?.platform},
                        {
                            key: "Betriebssystem",
                            value: deviceInfo?.operatingSystem,
                        },
                        {key: "Hersteller", value: deviceInfo?.manufacturer},
                        {
                            key: "Betriebssystem Version",
                            value: deviceInfo?.osVersion,
                        },
                        {
                            key: "Freier Arbeitsspeicher",
                            value: deviceInfo?.memUsed,
                        },
                        {
                            key: "Freier Festplattenspeicher",
                            value: deviceInfo?.realDiskFree,
                        },
                    ],
                };
            } else if (Capacitor.getPlatform() === "web") {
                systemInfo = {
                    title: "Device Info",

                    data: [
                        {key: "Gerätetyp", value: deviceInfo?.model},
                        {key: "Platform", value: deviceInfo?.platform},
                        {
                            key: "Betriebssystem",
                            value: deviceInfo?.operatingSystem,
                        },
                        {key: "Hersteller", value: deviceInfo?.manufacturer},
                        {
                            key: "Betriebssystem Version",
                            value: deviceInfo?.osVersion,
                        },
                    ],
                };
            } else {
                systemInfo = [];
            }
            return systemInfo;
        } catch (err) {
            console.log("Failed to get device infomation:", err);
        }
    }

    // #endregion
    // # region anonymisieren the data

    private anonymisiertData(data: any) {
    }

    //#endregion
}
