export const environment = {
    cpad2Server: "https://test.cpad2.de/#",
    smartSettingsServer: "https://test-api.cpad2.de/.well-known/smart-configuration",
    apiServer: "https://test-api.cpad2.de",
    mercureBaseUrl: "https://test-api.cpad2.de/.well-known/mercure",
    cpad2ServerGenerateJWT: true,
    cpad2ServerSubscriberTokenUrl: "/api/subscriberToken",
    mercureSubscriberToken: "eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyIqIl19fQ.sPE_gsY3x4Wmjcje2o6aqWeupWxx1m1p57aDXrTRrJE",
    production: false,
};
