import { createSelector } from '@ngrx/store';
import { AppState } from '../appstate.model';


// export interface SettingsState {
//     settings: Settings;
//     loading: boolean;
//     error: any;
//   }

// export interface Settings {
//     language: string;
//   }
export const selectSettingFeature = (state: AppState) => state.settings;

export const selectSettings = createSelector(
  selectSettingFeature,
  (settingsState) => settingsState.settings
);
// export const selectActiveAnonymous = createSelector(
//     selectSettings,
//     (settings) => settings.activeAnonymous
//   );
