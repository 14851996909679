import { createAction, props } from '@ngrx/store';
import { Settings } from '../../models/settings.model';

export const loadSettingState = createAction('[Settings] Load Settings');

export const setSettings = createAction(
  '[Settings] Set Settings',
  props<{ settings: Settings }>()
);

export const setSettingsSuccess = createAction(
  '[Settings] Set Settings Successful',
  props<{ settings: Settings }>()
);

export const setSettingsFail = createAction(
  '[Settings] Set Settings Fail',
  props<{ error: any }>()
);

export const loadSettingsSuccess = createAction(
  '[Settings] Load Settings Successful',
  props<{ settings: Settings }>()
);

export const loadSettingsFail = createAction(
  '[Settings] Load Settings Fail',
  props<{ error: any }>()
);

// #region for active Anonymous
// export const toggleActiveAnonymous = createAction('[Settings] Toggle Active Anonymous');
