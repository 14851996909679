import { Injectable } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class Cp2MatPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();

    public constructor(private translate: TranslateService) {}

    // For internationalization, the `$localize` function from
    // the `@angular/localize` package can be used.
    firstPageLabel = this.translate.instant(
        'GENERAL.MAT_PAGINATOR_INTL.label_first_page'
    );
    itemsPerPageLabel = this.translate.instant(
        'GENERAL.MAT_PAGINATOR_INTL.label_item_per_page'
    );
    lastPageLabel = this.translate.instant(
        'GENERAL.MAT_PAGINATOR_INTL.label_last_page'
    );

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';

    public getRangeLabel(
        page: number,
        pageSize: number,
        length: number
    ): string {
        if (length === 0) {
            return this.translate.instant(
                'GENERAL.MAT_PAGINATOR_INTL.label_page_1_of_1'
            );
        }

        const amountPages = Math.ceil(length / pageSize);
        return this.translate.instant(
            'GENERAL.MAT_PAGINATOR_INTL.label_page_n_of_total',
            { n: page+1, total: amountPages }
        );
        // return $localize`Page ${page + 1} of ${amountPages}`;
    }
}
