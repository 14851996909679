import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as SettingsSelectors from '../../store/selectors/setting.selector';
import * as SettingsActions from '../../store/actions/setting.action';
import {AppState} from '../../store/appstate.model';
import {Settings} from '../../models/settings.model';

@Injectable({
    providedIn: 'root',
})
export class SettingsFacadeService {
    /**
     * get settings fearture from the store.
     * return @param SettingsState
     */
    public settingsState$ = this.store.select(SettingsSelectors.selectSettingFeature);


    /**
     * get settings from the store.
     * return @param Settings
     */
    public settings$ = this.store.select(
        SettingsSelectors.selectSettings
    );

    public constructor(private store: Store<AppState>) {
    }

    /**
     * Loads the settings from persistent storage
     */
    public loadSettings(): void {
        this.store.dispatch(SettingsActions.loadSettingState());
    }

    /**
     * Dispatches an action to set a language into the store.
     */
    public saveSettings(settings: Settings): void {
        this.store.dispatch(SettingsActions.setSettings({settings}));
    }
}
