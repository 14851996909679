{
    "id": 2,
    "locator": "patient.list.9bb91e17-6079-4d1a-a931-dc631a3bf95c",
    "created_at": "2024-05-06T08:41:45Z",
    "resources": [
        "patient.781a8414-6aac-41f7-acf0-40809f736404",
        "patient.9bb91e17-6079-4d1a-a931-dc631a3bf95c",
        "patient.fd4d1ade-7605-45d3-a495-c98a2bdabd11",
        "patient.fd4d1ade-7605-45d3-a495-c98a2bdabd22",
        "patient.7293b941-6430-491c-a0eb-0dbacdd1943d",
        "patient.ddb8c954-8a2e-4904-a2ba-4d60569df1fe",
        "patient.95e20bd4-2da7-4995-8538-0c16ee967321",
        "patient.a24f8bc5-31b9-4e2a-b3d4-1c6f3e676b56",
        "patient.b33d4c5e-7b3e-441b-b8c6-f9988a32a6e8",
        "patient.c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890",
        "patient.d7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m",
        "patient.e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t",
        "patient.u1v2w3x4-y5z6-7a8b-9c0d-e1f2g3h4i5j6"
    ],
    "scope": "patient/781a8414-6aac-41f7-acf0-40809f736404.r,patient/9bb91e17-6079-4d1a-a931-dc631a3bf95c.r,patient/fd4d1ade-7605-45d3-a495-c98a2bdabd11.r,patient/fd4d1ade-7605-45d3-a495-c98a2bdabd22.r,patient/7293b941-6430-491c-a0eb-0dbacdd1943d.r,patient/ddb8c954-8a2e-4904-a2ba-4d60569df1fe.r,patient/95e20bd4-2da7-4995-8538-0c16ee967321.r,patient/a24f8bc5-31b9-4e2a-b3d4-1c6f3e676b56.r,patient/b33d4c5e-7b3e-441b-b8c6-f9988a32a6e8.r,patient/c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890.r,patient/d7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m.r,patient/e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t.r,patient/u1v2w3x4-y5z6-7a8b-9c0d-e1f2g3h4i5j6.r",
    "data": [
        {
            "id": "781a8414-6aac-41f7-acf0-40809f736404",
            "location": "HUK",
            "ward": "Station ASH",
            "room": "Zimmer 101",
            "bed": "Bett e",
            "firstName": "Norbert",
            "lastName": "Bleibusch",
            "gender": "Male",
            "dob": "1980-04-20",
            "admissionDate": "2024-04-09T10:30:00Z",
            "diagnosis": ["Meningitis", "Hypertonuns"],
            "tasks": [
                {
                    "referenceId": "f0f87d4b-3fc2-4f78-b0db-9dc4cf9412d4",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "editor": "",
                    "goalDate": "on",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174099",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "location": "HUK",
            "id": "9bb91e17-6079-4d1a-a931-dc631a3bf95c",
            "ward": "Station KARD",
            "room": "Zimmer 102",
            "bed": "Bett c",
            "firstName": "Helga",
            "lastName": "Mohn",
            "gender": "Female",
            "dob": "1980-08-12",
            "admissionDate": "2024-04-11T00:00:00Z",
            "diagnosis": ["Appendizitis"],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "tasks": [
                {
                    "referenceId": "f7c8e28b-49cd-4e55-b89e-93f4f658ecaa",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-13",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                },
                {
                    "referenceId": "f7c8e28b-49cd-3455-b89e-93f4f658ecaa",
                    "task_name": "Konsil Kardiologie anmelden",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-425514174033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "fd4d1ade-7605-45d3-a495-c98a2bdabd11",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 103",
            "bed": "Bett b",
            "firstName": "Alexander",
            "lastName": "Besenginster",
            "gender": "Male",
            "dob": "1980-02-28",
            "admissionDate": "2024-04-08T00:00:00Z",
            "diagnosis": ["Rektum CA"],
            "tasks": [
                {
                    "referenceId": "f7c8e28b-49cd-4e55-b89e-93f4f658ecer",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-326614174033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "fd4d1ade-7605-45d3-a495-c98a2bdabd22",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 103",
            "bed": "Bett a",
            "firstName": "Bettina",
            "lastName": "Böschungsmyrthe",
            "gender": "Male",
            "dob": "1980-07-19",
            "admissionDate": "2024-04-07T00:00:00Z",
            "diagnosis": ["Appendizitis"],
            "tasks": [
                {
                    "referenceId": "f7c8e28b-49cd-4e55-b89e-93f4f658efda",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614184033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "7293b941-6430-491c-a0eb-0dbacdd1943d",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 103",
            "bed": "Bett f",
            "firstName": "Renate",
            "lastName": "Bleibusch",
            "gender": "Male",
            "dob": "1980-06-14",
            "admissionDate": "2024-04-09T00:00:00Z",
            "diagnosis": ["Meningitis", "Hypertonuns"],
            "tasks": [
                {
                    "referenceId": "f7c8e28b-4erd-4e55-b89e-93f4f658ecaa",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": "geplant"
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "143e4567-e89b-12d3-a456-426614174033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "ddb8c954-8a2e-4904-a2ba-4d60569df1fe",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 103",
            "bed": "Bett d",
            "firstName": "Walter",
            "lastName": "Bastardindigo",
            "gender": "Male",
            "dob": "1980-09-01",
            "admissionDate": "2024-04-08T00:00:00Z",
            "diagnosis": ["Rektum CA"],
            "tasks": [
                {
                    "referenceId": "f7c8e28b-4dw1-4e55-b89e-93f4f658ecaa",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e74b-12d3-a456-426614174033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "95e20bd4-2da7-4995-8538-0c16ee967321",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 103",
            "bed": "Bett g",
            "firstName": "Wilfried",
            "lastName": "Eisenbaum",
            "gender": "Male",
            "dob": "1980-12-11",
            "admissionDate": "2024-04-08T20:00:00Z",
            "diagnosis": ["Rektum CA"],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": "geplant"
                }
            ],
            "tasks": [
                {
                    "referenceId": "f7c8e256-49cd-4e55-b89e-93f4f658ecaa",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4456-e89b-12d3-a456-426614174033",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "a24f8bc5-31b9-4e2a-b3d4-1c6f3e676b56",
            "location": "HUK",
            "ward": "Station ASH",
            "room": "Zimmer 104",
            "bed": "Bett a",
            "firstName": "Monika",
            "lastName": "Acker-Winde",
            "gender": "Female",
            "dob": "1980-05-03",
            "admissionDate": "2024-04-09T10:00:00Z",
            "diagnosis": ["Meningitis", "Hypertonuns"],
            "tasks": [
                {
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "editor": "",
                    "goalDate": "on",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "referenceId": "4zr3b3d3-6430-1874-a0eb-0dfdefd194l3",
                    "modifiedAt": "2024-04-14T09:35:00",
                    "modifiedBy": "Dr. Schmidt"
                },
                {
                    "task_name": "Vitalzeichenkontrolle 1x/d",
                    "details": "",
                    "priority": "normal",
                    "editor": "",
                    "goalDate": "on",
                    "goalDateOn": "2024-04-11",
                    "done": true,
                    "contractor": "Becker",
                    "referenceId": "4zr3b3d3-6430-1874-a0eb-0dfdefd194l3",
                    "modifiedAt": "2024-04-14T09:40:00",
                    "modifiedBy": "Dr. Schmidt"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": "",
                    "referenceId": "4zr3b3d3-64ed-1874-a0eb-0dfdefd194l3",
                    "modifiedAt": "2024-04-12T09:35:00",
                    "modifiedBy": "Dr. Schmidt"
                },
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-15",
                    "comment": "geplant",
                    "referenceId": "4zr3b3d3-64ed-1874-a0eb-0dfdefd194l3",
                    "modifiedAt": "2024-04-13T09:45:00",
                    "modifiedBy": "Dr. Schmidt"
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "note": "Kost ab Übermorgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-16T00:00:00Z"
                },
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "modifiedAt": "2024-04-15T12:00:00Z",
                    "note": "5 mal/d Kühlung",
                    "modifiedBy": "Alice Johnson"
                },
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "modifiedAt": "2024-04-13T12:00:00Z",
                    "note": "3 mal/d Kühlung",
                    "modifiedBy": "Dr. Miko Bauer"
                },
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "modifiedAt": "2024-04-14T12:00:00Z",
                    "note": "2 mal/d Kühlung",
                    "modifiedBy": "Dr. Miko Bauer"
                },
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "note": "O2 abgesetzt morgen",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                },
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                    "note": "Kost ab morgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "b33d4c5e-7b3e-441b-b8c6-f9988a32a6e8",
            "location": "HUK",
            "ward": "Station KARD",
            "room": "Zimmer 105",
            "bed": "Bett h",
            "firstName": "Frank",
            "lastName": "Apfel",
            "gender": "Male",
            "dob": "1980-01-31",
            "admissionDate": "2024-04-07T00:00:00Z",
            "diagnosis": ["Appendizitis"],
            "tasks": [
                {
                    "referenceId": "f7c5828b-49cd-4e55-b89e-93f4f658ecaa",
                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-13",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T08:00:00Z",
                    "modifiedBy": "Becker"
                },
                {
                    "referenceId": "f7c5828b-49cd-4e55-b89e-93f4f658ecaa",
                    "task_name": "Konsil Kardiologie anmelden",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-09T10:00:00Z",
                    "modifiedBy": "Becker"
                },
                {
                    "referenceId": "f7c58279-49cd-4e55-b89e-93f4f658ecaa",

                    "task_name": "Labor CRP E-lyte",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-19",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                },
                {
                    "referenceId": "f7d3828b-49cd-4e55-b89e-93f4f658ecaa",

                    "task_name": "Bett auf Intensivstation für morgen ca. 12:00 reservieren",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-21",
                    "done": false,
                    "contractor": "Bauer",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                },
                {
                    "referenceId": "f7d3828b-49cd-4r55-b89e-93f4f658ecdd",

                    "task_name": "Bett auf Intensivstation für morgen ca. 10:00 reservieren und vorbereiten",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-24",
                    "done": false,
                    "contractor": "Bauer",
                    "modifiedAt": "2024-04-12T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174033",
                    "note": "Kost ab übermorgen Vollkost und O2 abgesetzt morgen und VC einnehmen",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890",
            "location": "HUK",
            "ward": "Station GAST",
            "room": "Zimmer 106",
            "bed": "Bett a",
            "firstName": "Charlotte",
            "lastName": "Drachenbaum",
            "gender": "Female",
            "dob": "1980-11-07",
            "admissionDate": "2024-04-09T07:00:00Z",
            "diagnosis": ["Meningitis", "Hypertonuns"],
            "tasks": [
                {
                    "referenceId": "f7c5828b-49cd-cd55-b89e-93f4f658ecaa",

                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174044",
                    "note": "Kost ab morgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "d7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m",
            "location": "HUK",
            "ward": "Station D",
            "room": "Zimmer 107",
            "bed": "Bett b",
            "firstName": "Martin",
            "lastName": "Baummispel",
            "gender": "Male",
            "dob": "1980-03-05",
            "admissionDate": "2024-04-08T00:00:00Z",
            "diagnosis": ["Rektum CA"],
            "tasks": [
                {
                    "referenceId": "fd45828b-49cd-4e55-b89e-93f4f658ecaa",

                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174066",
                    "note": "Kost ab morgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t",
            "location": "HUK",
            "ward": "Station ASH",
            "room": "Zimmer 108",
            "bed": "Bett c",
            "firstName": "Heidi",
            "lastName": "Duftblattpelargonie",
            "gender": "Female",
            "dob": "1980-09-29",
            "admissionDate": "2024-04-07T00:00:00Z",
            "diagnosis": ["Appendizitis"],
            "tasks": [
                {
                    "referenceId": "f7c5828b-49cd-4rn5-b89e-93f4f658ecaa",

                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174055",
                    "note": "Kost ab morgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        },
        {
            "id": "u1v2w3x4-y5z6-7a8b-9c0d-e1f2g3h4i5j6",
            "location": "HUK",
            "ward": "Station KARD",
            "room": "Zimmer 109",
            "bed": "Bett e",

            "firstName": "Helmut",
            "lastName": "Avocado",
            "gender": "Male",
            "dob": "1980-10-24",
            "admissionDate": "2024-04-08T09:10:00Z",
            "diagnosis": ["Rektum CA"],
            "tasks": [
                {
                    "referenceId": "f7c5828b-49cd-4tz5-b89e-93f4f658ecaa",

                    "task_name": "Vitalzeichenkontrolle 3x/d",
                    "details": "",
                    "priority": "normal",
                    "goalDate": "on",
                    "editor": "",
                    "goalDateOn": "2024-04-11",
                    "done": false,
                    "contractor": "Becker",
                    "modifiedAt": "2024-04-10T00:00:00Z",
                    "modifiedBy": "Becker"
                }
            ],
            "discharge": [
                {
                    "setInCheckpad": true,
                    "dismissalExpectedOn": "2024-04-14T00:00:00Z",
                    "comment": ""
                }
            ],
            "visitRecords": [
                {
                    "referenceId": "123e4567-e89b-12d3-a456-426614174088",
                    "note": "Kost ab morgen Vollkost",
                    "modifiedBy": "Dr. Lisa Rutsch",
                    "modifiedAt": "2024-04-12T00:00:00Z"
                }
            ]
        }
    ],
    "i18n": {
        "de": {
            "goal_date": "Zieldatum",
            "task_name": "Aufgabe",
            "priority": "Priorityät",
            "done": "Erledigt",
            "contractor": "Auftragnehmer",
            "editor": "Bearbeiter",
            "goalDateOn": "Zieldatum am",
            "details": "Details",
            "note": "Notiz",
            "goalDate": "Zieldatum"
        },
        "en": {
            "goal_date": "Goal Date",
            "task_name": "Task",
            "priority": "Priority",
            "done": "Done",
            "goalDateOn": "Goal Date On",
            "details": "Details",
            "editor": "Editor",
            "contractor": "Contractor",
            "note": "Note",
            "goalDate": "Goal Date"
        }
    }
}
