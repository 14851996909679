{
    "created_at": "2024-05-06T08:41:45Z",
    "serverStatus": {
        "title": "Serverstatus",
        "compType": "chart",
        "data": [
            {
                "name": "Backend Server",
                "active": false,
                "ip": "run.mocky.io/v3/da9795b7-7eef-4ac9-9e5f-af5fe9d47a19",
                "lastPingMs": 0
            },
            {
                "name": "Auth Server",
                "active": false,
                "ip": "run.mocky.io/v3/06599509-6e11-4c9e-b59d-64dd14d4aee1",
                "lastPingMs": 0
            },
            {
                "name": "FHIR Server",
                "active": false,
                "ip": "run.mocky.io/v3/e7e7b799-382e-4ff5-9274-af43839ca089",
                "lastPingMs": 0
            },
            {
                "name": "Mercure Server",
                "active": false,
                "ip": "run.mocky.io/v3/f9a50702-3e1e-48c2-b3fd-030a643398ac",
                "lastPingMs": 0
            }
        ]
    },
    "releaseInformations": {
        "title": "Release Notes",
        "compType": "collapse",
        "data": [
            {
                "header": [
                    {
                        "key": "main",
                        "value": "1.0.0"
                    },
                    {
                        "key": "sub",
                        "value": "2024-02-13"
                    }
                ],
                "content": [
                    {
                        "key": "Änderungen",
                        "value": "Erstveröffentlichung"
                    },
                    {
                        "key": "Details",
                        "value": "Initiale Version der Anwendung"
                    },
                    {
                        "key": "Hinweise",
                        "value": "Einführung der Anmeldungs-Funktion"
                    }
                ]
            },
            {
                "header": [
                    {
                        "key": "main",
                        "value": "1.1.0"
                    },
                    {
                        "key": "sub",
                        "value": "2024-03-15"
                    }
                ],
                "content": [
                    {
                        "key": "Änderungen",
                        "value": "Behebung von Fehlern im Zusammenhang mit der Authentifizierung"
                    },
                    {
                        "key": "Details",
                        "value": "Hinzufügen einer neuen Einstellungsseite"
                    },
                    {
                        "key": "Hinweise",
                        "value": "Optimierung der Leistung"
                    }
                ]
            },
            {
                "header": [
                    {
                        "key": "main",
                        "value": "1.2.0"
                    },
                    {
                        "key": "sub",
                        "value": "2024-04-20"
                    }
                ],
                "content": [
                    {
                        "key": "Änderungen",
                        "value": "Hinzufügen von Mehrsprachunterstützung"
                    },
                    {
                        "key": "Details",
                        "value": "Aktualisierung der Abhängigkeiten"
                    },
                    {
                        "key": "Hinweise",
                        "value": "Verbesserung der Sicherheitsmaßnahmen"
                    }
                ]
            }
        ]
    },
    "tokenInformations": {
        "title": "Tokeninformationen",
        "compType": "collapse",
        "data": [
            {
                "header": [
                    {
                        "key": "main",
                        "value": "Token345"
                    },
                    {
                        "key": "sub",
                        "value": "(JWT)"
                    }
                ],
                "content": [
                    {
                        "key": "Zweck des Tokens",
                        "value": "Berechtigung zur Verwaltung von Benutzerkonten und Zugriff auf geschützte Ressourcen."
                    },
                    {
                        "key": "Erstellungsdatum",
                        "value": "15. Februar 2024"
                    },
                    {
                        "key": "Ersteller des Tokens",
                        "value": "John Doe"
                    },
                    {
                        "key": "Ablaufdatum",
                        "value": "15. Februar 2025"
                    }
                ]
            },
            {
                "header": [
                    {
                        "key": "main",
                        "value": "Token123"
                    },
                    {
                        "key": "sub",
                        "value": "(JWT)"
                    }
                ],
                "content": [
                    {
                        "key": "Zweck des Tokens",
                        "value": "Berechtigung zur Authentifizierung und Autorisierung von API-Anfragen."
                    },
                    {
                        "key": "Erstellungsdatum",
                        "value": "20. Februar 2024"
                    },
                    {
                        "key": "Ersteller des Tokens",
                        "value": "Jane Smith"
                    },
                    {
                        "key": "Ablaufdatum",
                        "value": "20. Februar 2025"
                    }
                ]
            }
        ]
    },
    "buildInformations": {
        "title": "Versionsinformationen",
        "compType": "info",
        "data": []
    },
    "deviceInformations": {
        "title": "Geräteinformationen",
        "compType": "info",
        "data": []
    }
}
