{
    "id": 1,
    "locator": "patient.overview.781a8414-6aac-41f7-acf0-40809f736404",
    "created_at": "2024-05-06T08:41:45Z",
    "resources": [
        "patient.781a8414-6aac-41f7-acf0-40809f736404",
        "diagnose.40809f736404-6aac-41f7-acf0-781a8414"
    ],
    "scope": "patient/781a8414-6aac-41f7-acf0-40809f736404.r,diagnose/40809f736404-6aac-41f7-acf0-781a8414.r",
    "data": {
        "patientID": "7293b941-6430-491c-a0eb-0dbacdd1943d",
        "anamnesis": [
            {
                "anamnesis": "seit 2 Tagen zunehmende Kopfschmerzen und Müdigkeit",
                "date": "2024-04-10"
            }
        ],
        "diagnoses": [
            {
                "diagnose": "Meningitis, Hypertonuns"
            }
        ],
        "interactionModule": [],
        "therapy": [
            {
                "title": "Antibiotikatherapie",
                "details": "",
                "task": true,
                "editor": "Dr. Schmidt",
                "registered": true,
                "conducted": false,
                "goalDate": "on",
                "goalDateOn": "2024-04-09",
                "showDayCountForGoalDateAs": "day1",
                "dayCountDeactivateOn": null,
                "showOnlyInCurve": false,
                "created_at": "2024-04-09"
            }
        ],
        "diagnostics": [
            {
                "title": "Blutbild",
                "details": "",
                "task": true,
                "teams": {
                    "Labor": true,
                    "Punktion": false,
                    "TX": false,
                    "Radiologie": false
                },
                "registered": true,
                "conducted": false,
                "goalDateOn": "2024-04-10",
                "showDayCountForGoalDateAs": "",
                "dayCountDeactivate": "2024-04-10",
                "showJustInCurve": false
            },
            {
                "title": "Liquor für Mikrobiologie",
                "details": "",
                "task": true,
                "teams": {
                    "Labor": false,
                    "Punktion": true,
                    "TX": false,
                    "Radiologie": false
                },
                "registered": true,
                "conducted": false,
                "goalDateOn": "2024-04-10",
                "showDayCountForGoalDateAs": "",
                "dayCountDeactivate": "",
                "showJustInCurve": false
            }
        ],
        "pathogen": [
            {
                "name": "Meningkokken",
                "type": "",
                "date": "2024-04-09"
            }
        ],
        "antibiotics": [],
        "infectiology": [],
        "anticoagulation": [],
        "postTreatment": [],
        "consultations": [
            {
                "title": "Kardiologie",
                "details": "",
                "task": true,
                "editor": "Dr. Fritz",
                "registered": true,
                "performed": false,
                "goalDateOn": "2024-04-11",
                "dayCountForGoalDateShowAs": "",
                "dayCountDeactivate": "",
                "dayCountDeactivateOn": "",
                "showOnlyInCurve": false
            }
        ],
        "tasks": [
            {
                "task_name": "Vitalzeichenkontrolle 3x/d",
                "details": "",
                "priority": "normal",
                "editor": "",
                "goalDateOn": "2024-04-11",
                "done": false,
                "contractor": "Becker"
            }
        ],
        "DRGs": [
            {
                "targetDischarge": "15.04.2024",
                "plannedDischarge": "14.04.2024",
                "actualDischarge": "14.04.2024"
            }
        ],
        "discharge": [
            {
                "setInCheckpad": true,
                "dismissalExpectedOn": "2024-04-14",
                "comment": "geplant"
            }
        ],
        "visitRecords": [
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174003",
                "note": "Kost ab Übermorgen Vollkost",
                "modifiedBy": "Dr. Lisa Rutsch",
                "modifiedAt": "2024-04-16T00:00:00Z"
            },
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                "modifiedAt": "2024-04-15T12:00:00Z",
                "note": "5 mal/d Kühlung",
                "modifiedBy": "Alice Johnson"
            },
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                "modifiedAt": "2024-04-13T12:00:00Z",
                "note": "3 mal/d Kühlung",
                "modifiedBy": "Dr. Miko Bauer"
            },
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174000",
                "modifiedAt": "2024-04-14T12:00:00Z",
                "note": "2 mal/d Kühlung",
                "modifiedBy": "Dr. Miko Bauer"
            },
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174001",
                "note": "O2 abgesetzt morgen",
                "modifiedBy": "Dr. Lisa Rutsch",
                "modifiedAt": "2024-04-12T00:00:00Z"
            },
            {
                "referenceId": "123e4567-e89b-12d3-a456-426614174002",
                "note": "Kost ab morgen Vollkost",
                "modifiedBy": "Dr. Lisa Rutsch",
                "modifiedAt": "2024-04-12T00:00:00Z"
            }
        ]
    }
}
